import { NodeGeneralPage } from '../../../graphql-types';
import { FieldParagraphUnionWithVirtualType } from '../atoms/Paragraph/Paragraph';

export const isGeneralPageWithWebform = (pageData: unknown) => {
    const bodyContent = (pageData as { nodeGeneralPage: NodeGeneralPage })?.nodeGeneralPage
        ?.relationships?.field_body_content as FieldParagraphUnionWithVirtualType[];
    const webformParagraph = bodyContent?.find(
        (paragraph: FieldParagraphUnionWithVirtualType) => paragraph?.type === 'paragraph__webform'
    );

    const hasWebform = Boolean(webformParagraph);
    return hasWebform;
};
