import { AnimalSearchAnimalType } from '../../graphql-api';

export enum AnimalTypeDisplayValue {
    BARNYARD = 'barnyard pet',
    BIRD = 'bird',
    CAT = 'cat',
    DOG = 'dog',
    HORSE = 'horse',
    RABBIT = 'rabbit',
    SCALES_FINS_AND_OTHER = 'scales, fins, or other pet',
    SMALL_AND_FURRY = 'small & furry pet',
}

export enum AnimalTypeFromAnimal {
    BARNYARD = 'Barnyard',
    BIRD = 'Bird',
    CAT = 'Cat',
    DOG = 'Dog',
    HORSE = 'Horse',
    RABBIT = 'Rabbit',
    SCALES_FINS_AND_OTHER = 'Scales, Fins & Other',
    SMALL_AND_FURRY = 'Small & Furry',
}

export type AnimalType = [AnimalSearchAnimalType, AnimalTypeFromAnimal, AnimalTypeDisplayValue];

export const AnimalTypes: AnimalType[] = [
    [
        AnimalSearchAnimalType.BARNYARD,
        AnimalTypeFromAnimal.BARNYARD,
        AnimalTypeDisplayValue.BARNYARD,
    ],
    [AnimalSearchAnimalType.BIRD, AnimalTypeFromAnimal.BIRD, AnimalTypeDisplayValue.BIRD],
    [AnimalSearchAnimalType.CAT, AnimalTypeFromAnimal.CAT, AnimalTypeDisplayValue.CAT],
    [AnimalSearchAnimalType.DOG, AnimalTypeFromAnimal.DOG, AnimalTypeDisplayValue.DOG],
    [AnimalSearchAnimalType.HORSE, AnimalTypeFromAnimal.HORSE, AnimalTypeDisplayValue.HORSE],
    [AnimalSearchAnimalType.RABBIT, AnimalTypeFromAnimal.RABBIT, AnimalTypeDisplayValue.RABBIT],
    [
        AnimalSearchAnimalType.SCALES_FINS_AND_OTHER,
        AnimalTypeFromAnimal.SCALES_FINS_AND_OTHER,
        AnimalTypeDisplayValue.SCALES_FINS_AND_OTHER,
    ],
    [
        AnimalSearchAnimalType.SMALL_AND_FURRY,
        AnimalTypeFromAnimal.SMALL_AND_FURRY,
        AnimalTypeDisplayValue.SMALL_AND_FURRY,
    ],
];
