import fetch from 'isomorphic-fetch';
import { ApolloClient, ApolloLink, InMemoryCache, gql, createHttpLink } from '@apollo/client';

const typeDefs = gql`
    extend type Query {
        setCookieHeader: String
    }
`;

function getGraphUrl(): string {
    if (typeof window !== 'undefined') {
        const { hostname } = window.location;
        let domain = 'services-dev.petfinder.com';

        if (hostname === 'www-qa.petfinder.com') {
            domain = 'services-qa.petfinder.com';
        }

        if (hostname === 'www.petfinder.com') {
            domain = 'services.petfinder.com';
        }

        return `https://${domain}/graphql`;
    }
    return '';
}

function createApolloClient() {
    const linkConfig = [];
    const uri = getGraphUrl();
    if (uri !== '') {
        const httpLink = createHttpLink({
            uri, // Server URL (must be absolute)
            fetch,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }, // Additional fetch() options like `credentials` or `headers`
            credentials: 'include',
        });
        linkConfig.push(httpLink);

        return new ApolloClient({
            ssrMode: false,
            link: ApolloLink.from(linkConfig),
            cache: new InMemoryCache({}),
            typeDefs,
        });
    }
}

const client = createApolloClient();

export default client;
