import { AnimalTypeFromAnimal } from 'types/AnimalTypes';
import { NodeBreed } from '../../../../graphql-types';
import { ActiveFilter } from './useBreedFilters';

export enum FilterType {
    LEVELS,
    THRESHOLD,
    STRING_MATCH,
}

export enum Level {
    LOW = 1,
    MODERATE = 2,
    HIGH = 3,
}

export enum FilterLabelsDog {
    SIZE = 'Size',
    ENERGY_LEVEL = 'Energy Level',
    FRIENDLINESS = 'Friendliness',
    EASE_OF_TRAINING = 'Ease Of Training',
    GROOMING_REQUIREMENTS = 'Grooming Requirements',
    VOCALITY = 'Vocality',
    EXERCISE_REQUIREMENTS = 'Exercise Requirements',
    AFFECTION_NEEDS = 'Affection Needs',
}

export enum FilterLabelsCat {
    PLAYFULNESS = 'Playfulness',
    ACTIVITY_LEVEL = 'Activity Level',
    FRIENDLINESS = 'Friendliness',
    GROOMING_REQUIREMENTS = 'Grooming Requirements',
    VOCALITY = 'Vocality',
}

export type BreedFilterNumberValueGetter = (nodeBreed: NodeBreed) => number | null | undefined;
export type BreedFilterStringValueGetter = (nodeBreed: NodeBreed) => string | null | undefined;

export interface ValueRange {
    min: number;
    max: number;
}

export interface ThresholdData {
    label: string;
    getValue: BreedFilterNumberValueGetter;
}

export interface FilterData {
    filterLabel: string;
    filterType: FilterType;
    animalType: AnimalTypeFromAnimal.CAT | AnimalTypeFromAnimal.DOG;
    labelsByLevel?: Record<Level, string>;
    labelsByStringValue?: Record<string, string>;
    thresholdDataList?: ThresholdData[];
    getLevelValue?: BreedFilterNumberValueGetter;
    getStringMatchValue?: BreedFilterStringValueGetter;
}

export const LevelToValueRangeDog = {
    [Level.LOW]: {
        min: 0,
        max: 1,
    },
    [Level.MODERATE]: {
        min: 2,
        max: 3,
    },
    [Level.HIGH]: {
        min: 4,
        max: 5,
    },
};

export const LevelToValueRangeCat = {
    [Level.LOW]: {
        min: 0,
        max: 2,
    },
    [Level.MODERATE]: {
        min: 3,
        max: 6,
    },
    [Level.HIGH]: {
        min: 7,
        max: 10,
    },
};

export const ThresholdValueDog = 3;
export const ThresholdValueCat = 5;

export const getValueRangeFromLevel = (
    level: Level,
    animalType: AnimalTypeFromAnimal.CAT | AnimalTypeFromAnimal.DOG
) => {
    if (animalType === AnimalTypeFromAnimal.CAT) {
        return LevelToValueRangeCat[level];
    }
    return LevelToValueRangeDog[level];
};

const defaultLabelsByLevel: Record<Level, string> = {
    [Level.LOW]: 'Low',
    [Level.MODERATE]: 'Moderate',
    [Level.HIGH]: 'High',
};

export const filterDataByFilterLabelDog: Record<string, FilterData> = {
    [FilterLabelsDog.SIZE]: {
        filterLabel: FilterLabelsDog.SIZE,
        filterType: FilterType.STRING_MATCH,
        animalType: AnimalTypeFromAnimal.DOG,
        getStringMatchValue: (nodeBreed) => nodeBreed?.field_breed_size,
        labelsByStringValue: {
            sm: 'Small',
            md: 'Medium',
            lg: 'Large',
            xl: 'Extra Large',
        },
    },
    [FilterLabelsDog.ENERGY_LEVEL]: {
        filterLabel: FilterLabelsDog.ENERGY_LEVEL,
        filterType: FilterType.LEVELS,
        animalType: AnimalTypeFromAnimal.DOG,
        labelsByLevel: defaultLabelsByLevel,
        getLevelValue: (nodeBreed) => nodeBreed?.field_breed_attr_energy_level,
    },
    [FilterLabelsDog.FRIENDLINESS]: {
        filterLabel: FilterLabelsDog.FRIENDLINESS,
        filterType: FilterType.THRESHOLD,
        animalType: AnimalTypeFromAnimal.DOG,
        thresholdDataList: [
            {
                label: 'Toward Dogs',
                getValue: (nodeBreed) => nodeBreed?.field_breed_attr_friend_dogs,
            },
            {
                label: 'Toward Other Pets',
                getValue: (nodeBreed) => nodeBreed?.field_breed_attr_friend_pets,
            },
        ],
    },
    [FilterLabelsDog.EASE_OF_TRAINING]: {
        filterLabel: FilterLabelsDog.EASE_OF_TRAINING,
        filterType: FilterType.LEVELS,
        animalType: AnimalTypeFromAnimal.DOG,
        labelsByLevel: {
            [Level.LOW]: 'Easy',
            [Level.MODERATE]: 'Average',
            [Level.HIGH]: 'Difficult',
        },
        getLevelValue: (nodeBreed) => nodeBreed?.field_breed_attr_ease_training,
    },
    [FilterLabelsDog.GROOMING_REQUIREMENTS]: {
        filterLabel: FilterLabelsDog.GROOMING_REQUIREMENTS,
        filterType: FilterType.LEVELS,
        animalType: AnimalTypeFromAnimal.DOG,
        labelsByLevel: defaultLabelsByLevel,
        getLevelValue: (nodeBreed) => nodeBreed?.field_breed_attr_grooming_req,
    },
    [FilterLabelsDog.VOCALITY]: {
        filterLabel: FilterLabelsDog.VOCALITY,
        filterType: FilterType.LEVELS,
        animalType: AnimalTypeFromAnimal.DOG,
        labelsByLevel: defaultLabelsByLevel,
        getLevelValue: (nodeBreed) => nodeBreed?.field_breed_attr_vocality,
    },
    [FilterLabelsDog.EXERCISE_REQUIREMENTS]: {
        filterLabel: FilterLabelsDog.EXERCISE_REQUIREMENTS,
        filterType: FilterType.LEVELS,
        animalType: AnimalTypeFromAnimal.DOG,
        labelsByLevel: {
            [Level.LOW]: 'Moderate',
            [Level.MODERATE]: 'Significant',
            [Level.HIGH]: 'Rigorous',
        },
        getLevelValue: (nodeBreed) => nodeBreed?.field_breed_attr_exercise_req,
    },
    [FilterLabelsDog.AFFECTION_NEEDS]: {
        filterLabel: FilterLabelsDog.AFFECTION_NEEDS,
        filterType: FilterType.LEVELS,
        animalType: AnimalTypeFromAnimal.DOG,
        labelsByLevel: {
            [Level.LOW]: 'Independent',
            [Level.MODERATE]: 'Balanced',
            [Level.HIGH]: 'Cuddly',
        },
        getLevelValue: (nodeBreed) => nodeBreed?.field_breed_attr_affection_level,
    },
};

export const filterDataByFilterLabelCat: Record<string, FilterData> = {
    [FilterLabelsCat.PLAYFULNESS]: {
        filterLabel: FilterLabelsCat.PLAYFULNESS,
        filterType: FilterType.LEVELS,
        animalType: AnimalTypeFromAnimal.CAT,
        labelsByLevel: defaultLabelsByLevel,
        getLevelValue: (nodeBreed) => nodeBreed?.field_breed_attr_playfulness,
    },
    [FilterLabelsCat.ACTIVITY_LEVEL]: {
        filterLabel: FilterLabelsCat.ACTIVITY_LEVEL,
        filterType: FilterType.LEVELS,
        animalType: AnimalTypeFromAnimal.CAT,
        labelsByLevel: defaultLabelsByLevel,
        getLevelValue: (nodeBreed) => nodeBreed?.field_breed_attr_activity_level,
    },
    [FilterLabelsCat.FRIENDLINESS]: {
        filterLabel: FilterLabelsCat.FRIENDLINESS,
        filterType: FilterType.THRESHOLD,
        animalType: AnimalTypeFromAnimal.CAT,
        thresholdDataList: [
            {
                label: 'Toward Children',
                getValue: (nodeBreed) => nodeBreed?.field_breed_attr_friend_child,
            },
            {
                label: 'Toward Other Pets',
                getValue: (nodeBreed) => nodeBreed?.field_breed_attr_friend_pets,
            },
        ],
    },
    [FilterLabelsCat.GROOMING_REQUIREMENTS]: {
        filterLabel: FilterLabelsCat.GROOMING_REQUIREMENTS,
        filterType: FilterType.LEVELS,
        animalType: AnimalTypeFromAnimal.CAT,
        labelsByLevel: defaultLabelsByLevel,
        getLevelValue: (nodeBreed) => nodeBreed?.field_breed_attr_grooming_req,
    },
    [FilterLabelsCat.VOCALITY]: {
        filterLabel: FilterLabelsCat.VOCALITY,
        filterType: FilterType.LEVELS,
        animalType: AnimalTypeFromAnimal.CAT,
        labelsByLevel: defaultLabelsByLevel,
        getLevelValue: (nodeBreed) => nodeBreed?.field_breed_attr_vocality,
    },
};

export function getLevelFromLabel(
    filterLabel: string,
    levelLabel: string,
    animalType: AnimalTypeFromAnimal
): Level | null {
    const filterData =
        animalType === AnimalTypeFromAnimal.DOG
            ? filterDataByFilterLabelDog[filterLabel]
            : filterDataByFilterLabelCat[filterLabel];

    if (!filterData || filterData.filterType !== FilterType.LEVELS) {
        return null;
    }

    const labelsByLevel = filterData.labelsByLevel;

    if (labelsByLevel?.[Level.LOW] === levelLabel) {
        return Level.LOW;
    } else if (labelsByLevel?.[Level.MODERATE] === levelLabel) {
        return Level.MODERATE;
    } else if (labelsByLevel?.[Level.HIGH] === levelLabel) {
        return Level.HIGH;
    }

    return null;
}

function applyFilterLevels(
    activeFilter: ActiveFilter,
    currentListOfBreeds: NodeBreed[],
    filterData: FilterData
) {
    const acceptedValueRanges = activeFilter.activeLabels
        .map((levelLabel) => {
            const level = getLevelFromLabel(
                activeFilter.filterLabel,
                levelLabel,
                filterData.animalType
            );
            if (!level) {
                return null;
            }
            return getValueRangeFromLevel(level, filterData.animalType);
        })
        .filter(Boolean) as ValueRange[];

    return currentListOfBreeds.filter((breed) => {
        const valueToFilterBy = filterData.getLevelValue?.(breed);
        if (!valueToFilterBy) {
            return false;
        }
        // return true if this value appears in any of the accepted value ranges
        const acceptedRange = acceptedValueRanges.find((valueRange) => {
            return valueToFilterBy >= valueRange.min && valueToFilterBy <= valueRange.max;
        });

        return Boolean(acceptedRange);
    });
}

function applyFilterStringMatch(
    activeFilter: ActiveFilter,
    currentListOfBreeds: NodeBreed[],
    filterData: FilterData
): NodeBreed[] {
    return currentListOfBreeds.filter((breed) => {
        const keyToFilterBy = filterData.getStringMatchValue?.(breed);
        if (!keyToFilterBy) {
            return false;
        }
        const valueToFilterBy = filterData.labelsByStringValue?.[keyToFilterBy];
        if (!valueToFilterBy) {
            return false;
        }
        const accepted = activeFilter.activeLabels.includes(valueToFilterBy);
        return accepted;
    });
}

function applyFilterThreshold(
    activeFilter: ActiveFilter,
    currentListOfBreeds: NodeBreed[],
    filterData: FilterData
): NodeBreed[] {
    let filteredBreeds = currentListOfBreeds;
    activeFilter.activeLabels.forEach((activeLabel) => {
        const thresholdData = filterData?.thresholdDataList?.find((thresholdData) => {
            return thresholdData.label === activeLabel;
        });
        if (!thresholdData) {
            return;
        }

        filteredBreeds = filteredBreeds.filter((breed) => {
            const valueToFilterBy = thresholdData.getValue(breed);

            if (!valueToFilterBy) {
                return false;
            }

            const thresholdToCheckAgainst =
                filterData.animalType === AnimalTypeFromAnimal.CAT
                    ? ThresholdValueCat
                    : ThresholdValueDog;
            const accepted = valueToFilterBy >= thresholdToCheckAgainst;
            return accepted;
        });
    });
    return filteredBreeds;
}

export function applyFilter(
    activeFilter: ActiveFilter,
    currentListOfBreeds: NodeBreed[],
    animalType: AnimalTypeFromAnimal.CAT | AnimalTypeFromAnimal.DOG
): NodeBreed[] {
    const filterLabelCat = activeFilter.filterLabel as FilterLabelsCat;
    const filterLabelDog = activeFilter.filterLabel as FilterLabelsDog;

    const filterData =
        animalType === AnimalTypeFromAnimal.CAT
            ? filterDataByFilterLabelCat[filterLabelCat]
            : filterDataByFilterLabelDog[filterLabelDog];

    if (activeFilter.filterType === FilterType.LEVELS) {
        return applyFilterLevels(activeFilter, currentListOfBreeds, filterData);
    } else if (activeFilter.filterType === FilterType.STRING_MATCH) {
        return applyFilterStringMatch(activeFilter, currentListOfBreeds, filterData);
    } else if (activeFilter.filterType === FilterType.THRESHOLD) {
        return applyFilterThreshold(activeFilter, currentListOfBreeds, filterData);
    }

    return currentListOfBreeds;
}
