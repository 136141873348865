exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-article-index-tsx": () => import("./../../../src/templates/Article/index.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */),
  "component---src-templates-breed-collection-index-tsx": () => import("./../../../src/templates/BreedCollection/index.tsx" /* webpackChunkName: "component---src-templates-breed-collection-index-tsx" */),
  "component---src-templates-breed-detail-index-tsx": () => import("./../../../src/templates/BreedDetail/index.tsx" /* webpackChunkName: "component---src-templates-breed-detail-index-tsx" */),
  "component---src-templates-breeds-category-index-tsx": () => import("./../../../src/templates/BreedsCategory/index.tsx" /* webpackChunkName: "component---src-templates-breeds-category-index-tsx" */),
  "component---src-templates-category-index-tsx": () => import("./../../../src/templates/Category/index.tsx" /* webpackChunkName: "component---src-templates-category-index-tsx" */),
  "component---src-templates-general-index-tsx": () => import("./../../../src/templates/General/index.tsx" /* webpackChunkName: "component---src-templates-general-index-tsx" */),
  "component---src-templates-homepage-index-tsx": () => import("./../../../src/templates/Homepage/index.tsx" /* webpackChunkName: "component---src-templates-homepage-index-tsx" */),
  "component---src-templates-tertiary-category-index-tsx": () => import("./../../../src/templates/TertiaryCategory/index.tsx" /* webpackChunkName: "component---src-templates-tertiary-category-index-tsx" */)
}

